<template>
  <BaseForm
    @submit="submit"
    customClass="login-form"
  >
    <div class="login-header">
      <img
        src="@/assets/img/logo.png"
        class="logo"
        alt="Logo"
      />
      <h4>Войдите, чтобы продолжить</h4>
    </div>

    <BaseInput
      v-model="email"
      autofocus
      label="Почта"
      placeholder="Введите вашу почту"
      :error-messages="checkErrors('email')"
    />

    <BaseInput
      v-model="password"
      label="Пароль"
      placeholder="Введите пароль"
      type="password"
      :error-messages="checkErrors('password')"
    />

    <div class="text-right">
      <BaseButton
        :label="'Войти'"
        :disabled="isLoading"
        @click="submit"
      />
    </div>
  </BaseForm>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import validationErrors from '@/components/mixins/validationErrors'
import { mapActions } from 'vuex'
import BaseButton from '@/components/baseNew/BaseButton.vue'
import BaseInput from '@/components/baseNew/BaseInput.vue'
import BaseForm from '@/components/baseNew/BaseForm.vue'

export default {
  name: 'LoginForm',
  components: {
    BaseButton,
    BaseInput,
    BaseForm
  },
  mixins: [validationErrors],
  validations: {
    email: { required },
    password: { required }
  },
  data () {
    return {
      email: '',
      password: '',
      isLoading: false
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    async submit () {
      if (this.isLoading) return

      this.isLoading = true
      this.$v.$touch()

      if (!this.$v.$anyError) {
        await this.login({ email: this.email, password: this.password })
      }

      this.isLoading = false
    }
  }
}
</script>

<style scoped>
  .login-form {
    max-width: 400px;
    margin: 0 auto;
  }
  .login-header {
    text-align: center;
    padding: 16px 0;
  }
  .logo {
    width: 213px;
    margin-bottom: 8px;
    opacity: 0.8;
  }
  .text-right {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
  }
</style>
