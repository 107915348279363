<template>
  <form
    :class="['base-form', customClass]"
    @submit.prevent="$emit('submit')"
    @reset="$emit('reset')"
    @keydown="handleTabKey"
  >
    <slot/>
  </form>
</template>

<script>
export default {
  name: 'BaseForm',
  props: {
    customClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleTabKey (event) {
      const formElements = this.$el.querySelectorAll('input, button, select, textarea, a[href], [tabindex]:not([tabindex="-1"])')
      const firstElement = formElements[0]
      const lastElement = formElements[formElements.length - 1]

      if (event.key === 'Tab') {
        if (event.shiftKey) {
          // Если shift + Tab, и фокус на первом элементе — перемещаем его на последний
          if (document.activeElement === firstElement) {
            event.preventDefault()
            lastElement.focus()
          }
        } else {
          // Если только Tab, и фокус на последнем элементе — перемещаем его на первый
          if (document.activeElement === lastElement) {
            event.preventDefault()
            firstElement.focus()
          }
        }
      }
    }
  }
}
</script>

<style scoped>
  .base-form {
    padding: 16px;
    background: #fff;
    box-shadow: 1px 12px 12px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
</style>
