<template>
  <div class="base-input-wrapper">
    <label
      v-if="label"
      :for="computedId"
      class="base-input-label"
    >
      {{ label }}
    </label>
    <input
      ref="input"
      :id="computedId"
      :type="type"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
      class="base-input"
      :class="{ 'input-error': errorMessages.length }"
    />
    <ul
      v-if="errorMessages.length"
      class="base-input-errors"
    >
      <li
        v-for="(error, index) in errorMessages"
        :key="index"
        class="base-input-error"
      >
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    label: { type: String, default: '' },
    value: { type: [String, Number], default: '' },
    placeholder: { type: String, default: '' },
    type: { type: String, default: 'text' },
    inputId: { type: String, default: '' },
    errorMessages: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false }
  },
  computed: {
    computedId () {
      return this.inputId || `input-${this._uid}`
    }
  },
  async mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.autofocus && !this.disabled && this.$refs.input) {
          this.$refs.input.focus()
        }
      }, 1513)
    })
  }
}
</script>

<style scoped>
  .base-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .base-input-label {
    font-size: 14px;
    color: #374151;
  }
  .base-input {
    padding: 10px 14px;
    font-size: 14px;
    border: 2px solid #d1d5db;
    border-radius: 6px;
    background: #fff;
    color: #111827;
    transition: border-color 0.3s, box-shadow 0.3s;
  }
  .base-input:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.25);
  }
  .input-error {
    border-color: #ef4444;
  }
  .base-input-errors {
    list-style: none;
    padding: 0;
    margin: 4px 0 0;
  }
  .base-input-error {
    color: #ef4444;
    font-size: 12px;
  }
  .base-input:disabled {
    background: #f3f4f6;
    color: #9ca3af;
    cursor: not-allowed;
  }
</style>
