<template>
  <button
    class="base-button"
    :class="{ disabled: disabled }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
  .base-button {
    padding: 10px 20px;
    font-size: 14px;
    color: #ffffff;
    background-color: #3b82f6;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .base-button:hover:not(.disabled) {
    background-color: #2563eb;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  .base-button.disabled {
    background-color: #d1d5db;
    color: #9ca3af;
    cursor: not-allowed;
    box-shadow: none;
  }
</style>
